import React, { useState, useEffect } from "react";
import Header from "./Header";

const TEacher = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch all teachers from the API on component mount
    const fetchTeachers = async () => {
      try {
        const response = await fetch(
          "https://api.erpschools.in/api/attendance/staff",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ action: "fetchTeachers" }), // Adjust the body as needed for your API
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching teachers");
        }
        const data = await response.json();
        // Assuming the API returns an array of teachers
        console.log(data);
        setAttendanceData(data.allAttendanceRecords);
      } catch (error) {
        console.error("Failed to fetch teachers:", error);
      }
    };

    fetchTeachers();
  }, []);

  const handleStatusToggle = async (teacherId) => {
    console.log(teacherId);
    const currentTeacher = attendanceData.find(
      (teacher) => teacher.id === teacherId
    );

    if (currentTeacher) {
      // Determine the updated status
      const updatedStatus =
        currentTeacher.status === "present" ? "absent" : "present";

      // Create the request data including the updated status
      const requestData = {
        staff_id: teacherId,
        // Include the updated status
      };

      try {
        // Make the POST request to mark attendance
        const response = await fetch(
          "https://api.erpschools.in/api/attendance/markstaffattendance",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        // Check for a successful response
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        // Update the attendanceData state with the new status
        setAttendanceData((prevData) =>
          prevData.map((teacher) =>
            teacher.id === teacherId
              ? { ...teacher, status: updatedStatus }
              : teacher
          )
        );
      } catch (error) {
        console.error("Error updating attendance status:", error);
        // Optionally, display a user-friendly error message
        alert("Failed to update attendance status. Please try again.");
      }
    } else {
      console.error("Teacher not found in attendance data");
    }
  };

  const filteredData = attendanceData.filter((teacher) =>
    teacher.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="p-8 bg-gray-50 min-h-screen">
        <h1 className="text-2xl font-bold text-center mb-8 text-indigo-700">
          Teacher Attendance
        </h1>

        {/* Search and All Present Button */}
        <div className="flex justify-between items-center mb-6">
          {/* Search Input Field */}
          <div className="w-3/5">
            <input
              type="text"
              className="block w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by teacher name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Mark All Present Button */}
        </div>

        {/* Attendance Table */}
        <div className="overflow-x-auto shadow-md rounded-lg bg-white">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-indigo-100 text-indigo-700">
                <th className="py-3 px-6 text-left font-semibold border">
                  Teacher ID
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Teacher Name
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Attendance Status
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((row) => (
                  <tr key={row.id} className="hover:bg-gray-50">
                    <td className="py-4 px-6 border">{row.id}</td>
                    <td className="py-4 px-6 border">{row.name}</td>
                    <td className="py-4 px-6 border">{row.status}</td>
                    <td className="py-4 px-6 border">
                      <button
                        className={`py-1 px-4 rounded-md text-white font-semibold ${
                          row.status === "present"
                            ? "bg-red-500 hover:bg-red-400"
                            : "bg-green-500 hover:bg-green-400"
                        }`}
                        onClick={() => handleStatusToggle(row.id)}
                      >
                        {row.status === "present"
                          ? "Mark Absent"
                          : "Mark Present"}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-8 text-gray-500">
                    No teachers found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Attendance Summary */}
        <div className="mt-8 flex justify-between items-center">
          <div className="text-lg shadow-md p-32 font-semibold text-gray-700">
            Total Teachers:{" "}
            <span className="text-indigo-600">{attendanceData.length}</span>
          </div>
          <div className="text-lg shadow-md p-32 font-semibold text-gray-700">
            Present Teachers:{" "}
            <span className="text-indigo-600">
              {
                attendanceData.filter((teacher) => teacher.status === "present")
                  .length
              }
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TEacher;
